<template>
  <div class="inner-section">
         <card>
         </card>
        <body-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_tpm.training_calendar') }}
              <slot v-if="$route.query.id">
                {{ $t('globalTrans.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="training-calendar" :class="'btn btn-success text-light'">{{ $t('elearning_tpm.training_calendar') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="circular_memo_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                            </template>
                             <b-form-select
                                  plain
                                  v-model="formData.circular_memo_no"
                                  :options="circularList"
                                  id="circular_memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @change="getCircularMemoNo(formData.circular_memo_no)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-overlay :show="load">
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                          <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_id"
                                  :options="officeList"
                                  id="office_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.training_type_id"
                                :options="trainingTypeList"
                                id="training_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_category_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.training_category_id"
                                :options="trainingCategoryList"
                                id="training_category_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_title_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.training_title_id"
                                :options="trainingTitleList"
                                id="training_title_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Start Date" vid="training_start_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_start_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_iabm.training_start_date') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  v-model="formData.training_start_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training End Date" vid="training_end_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_end_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_iabm.training_end_date') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  v-model="formData.training_end_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Quarter" vid="training_quarter_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_quarter_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_tpm.training_quarter')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.training_quarter_id"
                                :options="trainingQuarterSetupList"
                                id="training_quarter_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Venue" vid="venue_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="venue_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_tpm.vanue')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.venue_id"
                                :options="venus"
                                id="venu_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.remarks_en')}}
                              </template>
                              <b-form-textarea
                                  rows="2"
                                  id="remarks"
                                  v-model="formData.remarks"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.remarks_bn')}}
                              </template>
                              <b-form-textarea
                                  rows="2"
                                  id="remarks_bn"
                                  v-model="formData.remarks_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <!-- <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button> -->
                            <router-link to="training-calendar" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                        </div>
                      </div>
                    </b-overlay>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </body-card>
  </div>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingCalendarStore, trainingCalendarUpdate, circularPublication, trainingCalendarShow, circularPublicationCalender } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.getCircularList()
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: 0,
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_id: 0,
        room_type_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        training_start_date: '',
        training_end_date: '',
        training_quarter_id: 0,
        venue_id: 0,
        remarks: '',
        remarks_bn: '',
        status: 1
      },
      load: false,
      circularLoading: false,
      officeTypeList: [],
      officeList: [],
      circularList: [],
      venus: [],
      trainingCategoryList: [],
      trainingTitleList: []
    }
  },
  computed: {
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formData.circular_memo_no': function (newValue) {
      // this.getCircularMemoNo(newValue)
    },
    'formData.org_id': function (newValue) {
      this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  methods: {
    async getFormData () {
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingCalendarShow + '/' + this.$route.query.id)
      if (!result.success) {
        this.formData = []
      } else {
        this.formData = result.data
        if (this.$route.query.id) {
          var valueToPush = {}
          valueToPush.value = result.data.circular_memo_no
          valueToPush.text = result.data.circular_memo_no
          this.circularList.push(valueToPush)
        }
      }
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        // org_id: this.$store.state.dataFilters.orgId
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationCalender + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
      } else {
        const listObject = result.data
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          }
        })
        this.circularList = tmpList
      }
      if (this.$route.query.id) {
        this.getFormData()
      }
      this.circularLoading = false
    },
    async getCircularMemoNo (getCircularMemoNo) {
      this.load = true
      const params = {
        circular_memo_no: getCircularMemoNo,
        table: 'iab_circular_publications'
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
      if (!result.success) {
       this.formData.fiscal_year_id = 0
        this.formData.org_id = 0
        this.formData.office_id = 0
        this.formData.office_type_id = 0
        this.formData.training_title_id = 0
        this.formData.training_type_id = 0
        this.formData.training_category_id = 0
        this.formData.training_start_date = ''
        this.formData.training_end_date = ''
        this.venus = []
      } else {
        this.lcErrorMsg = ''
        const trData = result.data
        this.formData.fiscal_year_id = trData.fiscal_year_id
        this.formData.org_id = trData.org_id
        this.formData.office_id = trData.office_id
        this.formData.office_type_id = trData.office_type_id
        this.formData.training_title_id = trData.training_title_id
        this.formData.training_type_id = trData.training_type_id
        this.formData.training_category_id = trData.training_category_id
        this.formData.training_start_date = trData.training_start_date
        this.formData.training_end_date = trData.training_end_date
        this.venus = this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1 && item.org_id === parseInt(trData.org_id))
      }
      this.load = false
    },
    async createData () {
      this.load = true
      let result = null
      if (this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${trainingCalendarUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingCalendarStore, this.formData)
      }

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.load = false
        this.$router.push('/training-e-learning-service/tpm/training-calendar')
      } else {
        this.load = false
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
